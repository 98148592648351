import { PDFDocument, rgb, StandardFonts, PageSizes, PDFImage, PDFPage } from 'pdf-lib';
import { Mutation, Action } from 'vuex-module-decorators';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { EdgeDocumentsApi } from '@trialcard/apigateway.client/edgeDocumentsApi';
import {Settings} from "~/services/settings";
import LayoutModule from "~/store/layout";

export declare class Options {
        label:string ;
        value :boolean;

}
export declare class PageStringObjects {
    sr:number;
	type: string;
	label: string;
	value: string  ;
    lineSpacing?:number;
    Options?: Options[]
}

@InjectModule({ stateFactory: true }, module)
export default class PdfGenerationModule extends InjectVuexModule {
    public static documentFontRegular = StandardFonts.Helvetica;
	public static documentFontBold = StandardFonts.HelveticaBold;
	public static fontSizeRegular = 12;
	public static fontSizeLarge = 20;

    @Resolve
    public layout!: LayoutModule;

    @Resolve
    public settings!: Settings;

    @Resolve
    edgeDocumentsApi!: EdgeDocumentsApi;
    public hasDocument = false;

    @Action({ rawError: true })
    clear() {

    }
    consentPageData = {
        juvenile: false as boolean,
        patientFirstName: '' as string,
        patientLastName: '' as string,
        ParentGuardianName: '' as string,
        relationshipToPatient: '' as string,
        dob:'' as string,
        gender:'' as string,
        address1: '' as string,
        zip: '' as string,
        city: '' as string,
        state: '' as string,
        home: '' as string,
        cell: '' as string,
        email: '' as string,
        contactConsent: false as boolean,
        SignatureName: '' as string,
        todayDate: '' as string,

    }
    @Mutation
    public async updateconsentPageData(newconsentPageData: typeof PdfGenerationModule.prototype.consentPageData) {
        this.consentPageData =  newconsentPageData ;
    }
    @Action({ rawError: true })
    setDocumentStatus(newDocumentStatus: boolean) {
        this.hasDocument = newDocumentStatus;
    }
public async createPage(strings: PageStringObjects[]) {
    const pdfDoc = await PDFDocument.create();
    const fontRegular = await pdfDoc.embedFont(PdfGenerationModule.documentFontRegular);
    const fontBold = await pdfDoc.embedFont(PdfGenerationModule.documentFontRegular)

    // Add a blank page to the document
    const page = pdfDoc.addPage(PageSizes.Letter);
    const { width, height } = page.getSize();
    let currentHeight =  (height - 50)

    strings.forEach((item, index) => {
        const isTitle = item.type === 'title';
        const isCheckbox = item.type === 'checkbox';
        const size = isTitle ? PdfGenerationModule.fontSizeLarge : PdfGenerationModule.fontSizeRegular;
        const margin = (height - 50)

        // multiplying by 1.8 -- nearly doubles line spacing to make room for value string

        if (isTitle) {
            page.drawText(item.label, {
                x: width/2 -  fontBold.widthOfTextAtSize(item.label, 20)/2,
                y: currentHeight ,
                size: 20,
                font: fontBold,
                color: rgb(0, 0, 0),

            })
            currentHeight -= fontBold.heightAtSize(20) + 10
        }



            else if (isCheckbox){
                if (item.Options ?item.Options.length > 0 : false){
                    page.drawText(item.label, {
                        x: 50,
                        y:  currentHeight,
                        size,
                        font: fontBold,
                        color: rgb(0, 0, 0),
                    })

                    let checkboxlineWidth = fontRegular.widthOfTextAtSize(item.label, 12) +50
                    item.Options!.forEach(element =>{
                        const form = pdfDoc.getForm()
                        const checkBox = form.createCheckBox(item.sr.toString()+element.label)
                        checkBox.addToPage(page, {
                            x: checkboxlineWidth +10 ,
                            y:  currentHeight ,
                            width: 12,
                            height: 12,
                            textColor: rgb(0, 0, 0),
                            backgroundColor: rgb(1, 1, 1),
                            borderColor: rgb(0, 0, 0),
                            borderWidth: 2,

                          })
                        if (element.value){
                            checkBox.check()
                        }
                        checkBox.enableReadOnly()
                        if (element.label){
                            page.drawText(element.label, {
                                x: checkboxlineWidth +30,
                                y:  (currentHeight),
                                size,
                                font: fontBold,
                                color: rgb(0, 0, 0),
                            })
                        }

                        checkboxlineWidth = checkboxlineWidth + 20 + fontRegular.widthOfTextAtSize(element.label, 12) + 10
                    })

                }
                else{
                    const form = pdfDoc.getForm()
                    const checkBox = form.createCheckBox(item.sr.toString())
                    checkBox.addToPage(page, {
                        x: 50,
                        y:  currentHeight - 3,
                        width: 12,
                        height: 12,
                        textColor: rgb(0, 0, 0),
                        backgroundColor: rgb(1, 1, 1),
                        borderColor: rgb(0, 0, 0),
                        borderWidth: 2,
                      })
                      if(item.value === "1"){
                        checkBox.check()

                      }
                      checkBox.enableReadOnly()
                      page.drawText(item.label, {
                        x: 70,
                        y:  (currentHeight),
                        size,
                        maxWidth:500,
                        lineHeight: 13,
                        font: fontBold,
                        color: rgb(0, 0, 0),
                    })
                }
                currentHeight -= fontBold.heightAtSize(12)*2 + 10

        } else {
            if(index % 2 ===1){
                currentHeight += fontBold.heightAtSize(12) + 5;
            }
            page.drawText(item.label, {
                x: index % 2 ===0 ? 50 :  width/2,
                y:  (currentHeight),
                size,
                font: fontBold,
                color: rgb(0, 0, 0),

            })
            currentHeight -= fontBold.heightAtSize(12) + 5;
            page.drawText(item.value, {
                x: index % 2 ===0 ? 50 :  width/2,
                // x: index % 2 ===0 ? 55 +  fontBold.widthOfTextAtSize(item.label, size) :  width/2 +  fontBold.widthOfTextAtSize(item.label, size),
                y:  (currentHeight),
                size,
                font: fontRegular,
                color: rgb(0, 0, 0),
                lineHeight: 13,
                maxWidth:width/2 -100,

            })
            if(index % 2 ===1){
                currentHeight -= fontBold.heightAtSize(12) + 20
            }

        }

    }
    );

    return pdfDoc;
}

public async createPdf(strings: PageStringObjects[][]) {
    const pdfDoc = await PDFDocument.create()
    for (const stringArray of strings) {
        const page = await this.createPage(stringArray)
        const [copy] = await pdfDoc.copyPages(page, [0])
        pdfDoc.addPage(copy)
    }
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes.buffer], { type: 'application/pdf' });
    return blob
}

public async uploadPdf(strings: PageStringObjects[][]) {
    const blob = await this.createPdf(strings)
    const formData = new FormData();
    formData.append('type', '10001');
    formData.append('attachment', blob);

    formData.append('externalPartyCode',"CLS");
    formData.append('externalProgramCode',"SPA");
    const axios: AxiosInstance = (this.edgeDocumentsApi as any).axios;
    const axiosRequest: AxiosRequestConfig = {
        url: 'edge/documents/v1/Document/upload',
        method: 'POST',
        data: formData,
        headers: {
            'x-program-id': this.settings.clsProgramId!,
        },
    };

    if(this.layout.testPdf) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const fileName = 'ConsentPatientInformation';
        link.download = fileName;
        link.click();
    }

    await Promise.all([
        // (this.edgeDocumentsApi as any).authentications.ApiKey.applyToRequest(axiosRequest),
        // (this.edgeDocumentsApi as any).authentications.Bearer.applyToRequest(axiosRequest),
        (this.edgeDocumentsApi as any).authentications.Token.applyToRequest(axiosRequest),
    ]);

    const result = await axios.request(axiosRequest);
    return result;


}

public async downloadPdf(strings: PageStringObjects[][]) {
    const blob = await this.createPdf(strings)
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const fileName = 'ConsentPatientInformation';
    link.download = fileName;
    link.click();
}

public async combinePages(pages: PDFDocument[]) {
    const pdfDoc = await PDFDocument.create();

    const copiedPages = [] as PDFPage[]

    await pages.forEach(async (page, index) => {
        const [copyPage] = await pdfDoc.copyPages(page, [index])
        copiedPages.push(copyPage)
    })

    copiedPages.forEach(page => {
        pdfDoc.addPage(page)
    })

    const pdfBytes = await pdfDoc.save();

    // Serialize the PDFDocument to bytes (a Uint8Array)
    return pdfBytes;
}
}
