import { render, staticRenderFns } from "./FeatureSection.vue?vue&type=template&id=557dc19a&scoped=true&"
import script from "./FeatureSection.vue?vue&type=script&lang=ts&"
export * from "./FeatureSection.vue?vue&type=script&lang=ts&"
import style0 from "./FeatureSection.vue?vue&type=style&index=0&id=557dc19a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "557dc19a",
  null
  
)

export default component.exports