
































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import LayoutModule from '~/store/layout';

@Component<Footer>({})
export default class Footer extends Vue {
    @Resolve
    public layout!: LayoutModule;
    @Prop()
    public brkPoints!: object;
    @Prop({ default: false })
    public isFixed!: boolean;
    isShowDialogue = false;
    componentKey = 0;

    public get footerInfoLinkLocation() {
        return this.layout.configuration.footerInfoLinkLocation;
    }

    public get isTopOrBottomBar() {
        return ['top', 'bottom'].includes(this.footerInfoLinkLocation);
    }

    public get isLeftOrRightBar() {
        return ['left', 'right'].includes(this.footerInfoLinkLocation);
    }

    public get footerLogoSrc() {
        return this.layout.configuration.images.footer || this.$settings.url(this.$settings.footerLogo);
    }

    forceRerender() {
      this.componentKey += 1;
      this.isShowDialogue = !this.isShowDialogue;
    }

    isHCPHyrimoz() {
        return this.$route.path.startsWith("/provider/hyrimoz")
    }
}
