import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _34e19e72 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _5258f76b = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _eb398292 = () => interopDefault(import('..\\pages\\have-a-card\\index.vue' /* webpackChunkName: "pages_have-a-card_index" */))
const _69dd0122 = () => interopDefault(import('..\\pages\\have-a-card\\enrollment.vue' /* webpackChunkName: "pages_have-a-card_enrollment" */))
const _21d67628 = () => interopDefault(import('..\\pages\\have-a-card\\success.vue' /* webpackChunkName: "pages_have-a-card_success" */))
const _9a918202 = () => interopDefault(import('..\\pages\\prescription-information.vue' /* webpackChunkName: "pages_prescription-information" */))
const _19fe36f3 = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _88f72b26 = () => interopDefault(import('..\\pages\\success.vue' /* webpackChunkName: "pages_success" */))
const _ebcb77b4 = () => interopDefault(import('..\\pages\\thank-you.vue' /* webpackChunkName: "pages_thank-you" */))
const _559aa76c = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _2c499a60 = () => interopDefault(import('..\\pages\\upload-documents\\index.vue' /* webpackChunkName: "pages_upload-documents_index" */))
const _70b290da = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _709661d8 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _705e03d4 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _7041d4d2 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _06e61a58 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _51d11360 = () => interopDefault(import('..\\pages\\need-a-card\\eligibility.vue' /* webpackChunkName: "pages_need-a-card_eligibility" */))
const _cabeca26 = () => interopDefault(import('..\\pages\\need-a-card\\insurance.vue' /* webpackChunkName: "pages_need-a-card_insurance" */))
const _0eac7a68 = () => interopDefault(import('..\\pages\\need-a-card\\insurance-information.vue' /* webpackChunkName: "pages_need-a-card_insurance-information" */))
const _e86ed892 = () => interopDefault(import('..\\pages\\need-a-card\\patient-information.vue' /* webpackChunkName: "pages_need-a-card_patient-information" */))
const _69ea47fe = () => interopDefault(import('..\\pages\\need-a-card\\prescriber-information.vue' /* webpackChunkName: "pages_need-a-card_prescriber-information" */))
const _9be59cae = () => interopDefault(import('..\\pages\\need-a-card\\select-path.vue' /* webpackChunkName: "pages_need-a-card_select-path" */))
const _372bda56 = () => interopDefault(import('..\\pages\\need-a-card\\success.vue' /* webpackChunkName: "pages_need-a-card_success" */))
const _6fa904d5 = () => interopDefault(import('..\\pages\\patient\\i-want-to.vue' /* webpackChunkName: "pages_patient_i-want-to" */))
const _7d05d4cc = () => interopDefault(import('..\\pages\\patient\\insurance-information.vue' /* webpackChunkName: "pages_patient_insurance-information" */))
const _6e4e3292 = () => interopDefault(import('..\\pages\\patient\\patient-authorization.vue' /* webpackChunkName: "pages_patient_patient-authorization" */))
const _1d97da24 = () => interopDefault(import('..\\pages\\patient\\patientSearch.vue' /* webpackChunkName: "pages_patient_patientSearch" */))
const _097a0da4 = () => interopDefault(import('..\\pages\\patient\\success.vue' /* webpackChunkName: "pages_patient_success" */))
const _9940efca = () => interopDefault(import('..\\pages\\patient\\upload-documents.vue' /* webpackChunkName: "pages_patient_upload-documents" */))
const _38444aae = () => interopDefault(import('..\\pages\\provider\\hyrimoz.vue' /* webpackChunkName: "pages_provider_hyrimoz" */))
const _19a5272e = () => interopDefault(import('..\\pages\\provider\\i-would-like-to.vue' /* webpackChunkName: "pages_provider_i-would-like-to" */))
const _2cea0d0a = () => interopDefault(import('..\\pages\\provider\\patient-authorization.vue' /* webpackChunkName: "pages_provider_patient-authorization" */))
const _60fb3a4a = () => interopDefault(import('..\\pages\\provider\\patient-insurance-information.vue' /* webpackChunkName: "pages_provider_patient-insurance-information" */))
const _289fd6c7 = () => interopDefault(import('..\\pages\\provider\\prescriber-signature.vue' /* webpackChunkName: "pages_provider_prescriber-signature" */))
const _32eff9a0 = () => interopDefault(import('..\\pages\\provider\\prescription-information.vue' /* webpackChunkName: "pages_provider_prescription-information" */))
const _da7182f2 = () => interopDefault(import('..\\pages\\provider\\provider-information.vue' /* webpackChunkName: "pages_provider_provider-information" */))
const _3a4bba36 = () => interopDefault(import('..\\pages\\provider\\selection.vue' /* webpackChunkName: "pages_provider_selection" */))
const _8efdcbc8 = () => interopDefault(import('..\\pages\\provider\\success.vue' /* webpackChunkName: "pages_provider_success" */))
const _b6f32300 = () => interopDefault(import('..\\pages\\provider\\upload-document.vue' /* webpackChunkName: "pages_provider_upload-document" */))
const _2019faba = () => interopDefault(import('..\\pages\\provider\\upload-documents.vue' /* webpackChunkName: "pages_provider_upload-documents" */))
const _1dc8ff88 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _34e19e72,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _5258f76b,
    children: [{
      path: "",
      component: _eb398292,
      name: "have-a-card"
    }, {
      path: "enrollment",
      component: _69dd0122,
      name: "have-a-card-enrollment"
    }, {
      path: "success",
      component: _21d67628,
      name: "have-a-card-success"
    }]
  }, {
    path: "/prescription-information",
    component: _9a918202,
    name: "prescription-information"
  }, {
    path: "/rejection",
    component: _19fe36f3,
    name: "rejection"
  }, {
    path: "/success",
    component: _88f72b26,
    name: "success"
  }, {
    path: "/thank-you",
    component: _ebcb77b4,
    name: "thank-you"
  }, {
    path: "/unlock",
    component: _559aa76c,
    name: "unlock"
  }, {
    path: "/upload-documents",
    component: _2c499a60,
    name: "upload-documents"
  }, {
    path: "/errors/400",
    component: _70b290da,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _709661d8,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _705e03d4,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _7041d4d2,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _06e61a58,
    name: "errors-500"
  }, {
    path: "/need-a-card/eligibility",
    component: _51d11360,
    name: "need-a-card-eligibility"
  }, {
    path: "/need-a-card/insurance",
    component: _cabeca26,
    name: "need-a-card-insurance"
  }, {
    path: "/need-a-card/insurance-information",
    component: _0eac7a68,
    name: "need-a-card-insurance-information"
  }, {
    path: "/need-a-card/patient-information",
    component: _e86ed892,
    name: "need-a-card-patient-information"
  }, {
    path: "/need-a-card/prescriber-information",
    component: _69ea47fe,
    name: "need-a-card-prescriber-information"
  }, {
    path: "/need-a-card/select-path",
    component: _9be59cae,
    name: "need-a-card-select-path"
  }, {
    path: "/need-a-card/success",
    component: _372bda56,
    name: "need-a-card-success"
  }, {
    path: "/patient/i-want-to",
    component: _6fa904d5,
    name: "patient-i-want-to"
  }, {
    path: "/patient/insurance-information",
    component: _7d05d4cc,
    name: "patient-insurance-information"
  }, {
    path: "/patient/patient-authorization",
    component: _6e4e3292,
    name: "patient-patient-authorization"
  }, {
    path: "/patient/patientSearch",
    component: _1d97da24,
    name: "patient-patientSearch"
  }, {
    path: "/patient/success",
    component: _097a0da4,
    name: "patient-success"
  }, {
    path: "/patient/upload-documents",
    component: _9940efca,
    name: "patient-upload-documents"
  }, {
    path: "/provider/hyrimoz",
    component: _38444aae,
    name: "provider-hyrimoz"
  }, {
    path: "/provider/i-would-like-to",
    component: _19a5272e,
    name: "provider-i-would-like-to"
  }, {
    path: "/provider/patient-authorization",
    component: _2cea0d0a,
    name: "provider-patient-authorization"
  }, {
    path: "/provider/patient-insurance-information",
    component: _60fb3a4a,
    name: "provider-patient-insurance-information"
  }, {
    path: "/provider/prescriber-signature",
    component: _289fd6c7,
    name: "provider-prescriber-signature"
  }, {
    path: "/provider/prescription-information",
    component: _32eff9a0,
    name: "provider-prescription-information"
  }, {
    path: "/provider/provider-information",
    component: _da7182f2,
    name: "provider-provider-information"
  }, {
    path: "/provider/selection",
    component: _3a4bba36,
    name: "provider-selection"
  }, {
    path: "/provider/success",
    component: _8efdcbc8,
    name: "provider-success"
  }, {
    path: "/provider/upload-document",
    component: _b6f32300,
    name: "provider-upload-document"
  }, {
    path: "/provider/upload-documents",
    component: _2019faba,
    name: "provider-upload-documents"
  }, {
    path: "/",
    component: _1dc8ff88,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
