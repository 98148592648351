













































import Vue from 'vue';
import Component from 'vue-class-component';
import { Resolve } from 'vue-di';
import { Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { faGripHorizontal } from '@fortawesome/pro-duotone-svg-icons';
import LayoutModule from '~/store/layout';

@Component<PagesSection>({
    layout: 'default',
    components: {
        draggable,
    },
    icons: {
        faGripHorizontal: faGripHorizontal as any,
    },
})
export default class PagesSection extends Vue {
    @Resolve
    public layout!: LayoutModule;

    @Prop()
    public icon: any;

    public get pages() {
        return this.layout.configuration.pages;
    }

    public set pages(pages) {
        this.layout.setConfiguration({ pages });
    }

    public get otherPages() {
        return this.layout.configuration.otherPages;
    }

    public set otherPages(otherPages) {
        this.layout.setConfiguration({ otherPages });
    }
}
